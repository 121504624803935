<template>
  <div>
    <el-main>
      <el-row :gutter="10">
        <SmartClassifierNewIntent @handleBlur="handleBlur" :intents="intents" />
        <el-pagination
          background
          small
          layout="prev, pager, next"
          :pageSize="10"
          :total="intents.length"
          :current-page.sync="currentPage"
          class="inline-block"
        ></el-pagination>
        <div class="inline-block">
          <span class="has-text-grey">{{ intents.length }} Intents</span>
        </div>
      </el-row>
      <el-table
        ref="dataset"
        class="w-full"
        size="small"
        :data="pagedIntents"
        :row-key="(row, index) => index"
        @cell-click="handleCellClick"
        v-loading="isFetching"
      >
        <el-table-column
          width="200"
          sortable
          header-align="center"
          label="Intent Name"
          column-key="intentName"
          prop="intentName"
        >
          <template slot-scope="scope">
            <!-- new question -->
            <div v-if="currentSelectIntent?.id !== scope.row.id" class="ml-1em">
              {{ scope.row.intentName }}
            </div>
            <!-- v-else is an input with v-model is intent name -->
            <div v-else>
              <el-input
                v-model="scope.row.intentName"
                size="mini"
                placeholder="Intent Name"
                class="w-full"
              ></el-input>
            </div>
          </template>
        </el-table-column>

        <!-- Variation length -->
        <el-table-column
          sortable
          column-key="description"
          header-align="center"
          label="Intent Description"
          width="400"
          prop="description"
        >
          <template slot-scope="scope">
            <div class="single-line">{{ scope.row.description }}</div>
          </template>
        </el-table-column>

        <el-table-column header-align="center" label="Enabled" width="80" align="center">
          <template slot-scope="props">
            <el-switch v-model="props.row.enabled"></el-switch>
          </template>
        </el-table-column>
        <el-table-column header-align="center" width="48" align="center">
          <template slot-scope="props">
            <div>
              <el-tooltip content="Delete this intent" placement="right">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="handleDeleteIntent(props.$index)"
                />
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </div>
</template>

<script>
import SmartClassifierNewIntent from "./SmartClassifierNewIntent.vue";

export default {
  components: {
    SmartClassifierNewIntent,
  },
  props: {
    currentSelectIntent: {
      type: Object,
      required: false,
    },
    intents: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      expandedRows: [],
      defaultIntentNameValue: "Unnamed Intent",
      badgeForQustions: {
        1: false,
        2: true,
        3: false,
        4: false,
        5: true,
      },
      currentPage: 1,
    };
  },
  methods: {
    handleCellClick(row, column, cell, event) {
      if (column.columnKey === "intentName" || column.columnKey === "description") {
        this.$emit("handleIntentEdit", row);
      }
    },
    handleBlur(newIntent) {
      this.$emit("handleAddIntent", newIntent);
    },
    handleDeleteIntent(index) {
      this.$confirm("Are you sure you want to delete this intent?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("SMART_CLASSIFIER_DELETE_INTENT", index);
        })
        .catch(() => {});
    },
  },
  computed: {
    pagedIntents() {
      return this.intents.slice((this.currentPage - 1) * 10, this.currentPage * 10);
    },
    isFetching() {
      return this.$store?.state?.smart_classifier?.isFetching;
    },
  },
};
</script>

<style lang="scss" scoped>
.single-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 80%;
}

.inline-block {
  display: inline-block;
}

.inline-block span {
  font-size: 10px;
}

.ml-1em {
  margin-left: 1em;
}
</style>
